jQuery(document).ready(function ($) {
    var stickyOffset = $('.site-header-top');

    if (!stickyOffset.length) return

    stickyOffset = $(stickyOffset).offset().top + $(stickyOffset).innerHeight();

    function fixed_header(scroll) {
        var sticky = $('.header-fixed');
        // var scroll = $(window).scrollTop();

        if (scroll >= stickyOffset) {
            sticky.addClass('fixed');
        } else {
            sticky.removeClass('fixed');
        }
    }

    fixed_header($(window).scrollTop());

    $(window).scroll(function () {
        fixed_header($(window).scrollTop());
    });
});