jQuery(document).ready(function($) {    
    var $backTop = $('.back-top');

    if(!$backTop.length) return
    
    $backTop.on('click', function(e) {
        
        e.preventDefault();

        $('html, body').animate({
            scrollTop: $('body').offset().top
        }, 600);
    });
});