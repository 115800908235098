// jQuery(document).ready(function ($) {
//     var $slide = $('.home-slide .swiper');

//     if (!$slide.length) return

//     var mySwiper = new Swiper($slide, {
//         autoplay: {
//             delay: 7000,
//         },
//         speed: 550,
//         navigation: {
//             prevEl: $slide.parent().find('.slide-navigation .prev'),
//             nextEl: $slide.parent().find('.slide-navigation .next')
//         },
//     });

//     $slide.mouseover(function () {
//         mySwiper.autoplay.stop();
//     }).mouseout(function () {
//         mySwiper.autoplay.start();
//     });
// });

jQuery(document).ready(function ($) {
    const $slide = $(".home-slide .swiper");

    if (!$slide) return;

    var mySwiper = new Swiper(".home-slide .swiper", {
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        slidesPerView: 1,
        speed: 400,
        navigation: {
            nextEl: ".slide-navigation .next",
            prevEl: ".slide-navigation .prev",
        }
    });

    $slide.mouseover(function () {
        mySwiper.autoplay.stop();
    }).mouseout(function () {
        mySwiper.autoplay.start();
    });
});
